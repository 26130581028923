import React, { ReactElement, useMemo, useState } from "react";
import Image from "next/image";
import styles from "./styles.module.scss";
import { TCategoryList } from "@page-builder/ts/generated";
import { useDomainContext } from "@hooks/useDomainContext";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useRouter } from "next/router";
import { replacePlaceholders } from "@lib/sharedUtils";

export default function CategoryList({
    props = {},
}: {
    props?: TCategoryList["props"];
}): ReactElement {
    const [searchValue, setSearchValue] = useState("");
    const { query } = useRouter();
    const {
        title,
        description,
        categoriesList,
        addLinkButton,
        buttonText,
        enableSearch,
        searchPlaceholder,
    } = props;
    const { domain } = useDomainContext();
    const domainCategories = domain?.categories;
    const queryString = gettingQueriesAsString(query, "start", [
        "genericRoute",
        "keyword",
        "replaceKeyword",
        "slug",
    ]);
    const { visitDetails, category } = useDomainContext();

    const finalCategoriesList = useMemo(() => {
        const mappedCategories: {
            link: string;
            title: string;
            description: string;
            icon: string;
            buttonText: string;
        }[] = [];

        if (!categoriesList || categoriesList?.auto) {
            domainCategories?.forEach((category) => {
                mappedCategories.push({
                    link: `/${category.slugAlias ?? category.slug}`,
                    title: category.label ?? category.name ?? "",
                    description: "",
                    icon: category.icon?.fullPath ?? "",
                    buttonText: buttonText ?? "Get A Free Quote",
                });
            });
        } else {
            categoriesList.categories?.forEach((category) => {
                mappedCategories.push({
                    link: `${category.link ?? ""}`,
                    title: category.title ?? "",
                    description: category.description ?? "",
                    icon: category.image ?? "",
                    buttonText:
                        category.buttonText ?? buttonText ?? "Get A Free Quote",
                });
            });
        }

        return mappedCategories.filter((el) => {
            return el.title
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase());
        });
    }, [categoriesList, domainCategories, searchValue]);

    return (
        <div className={`${styles["category-list"]} `}>
            <div>
                {title && (
                    <div
                        className={`mb-6 ${styles.title}`}
                        dangerouslySetInnerHTML={{
                            __html: replacePlaceholders(title, {
                                region: visitDetails.region,
                                city: visitDetails?.city,
                                category: category?.label ?? category?.name,
                            }),
                        }}
                    />
                )}
                {description && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: replacePlaceholders(description, {
                                region: visitDetails.region,
                                city: visitDetails?.city,
                                category: category?.label ?? category?.name,
                            }),
                        }}
                        className={styles.description}
                    />
                )}
                {enableSearch && (
                    <div className="flex">
                        <input
                            type="text"
                            className={styles.search}
                            placeholder={searchPlaceholder || "Search..."}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                )}
            </div>

            {finalCategoriesList.length ? (
                <div className={styles["categories"]}>
                    {finalCategoriesList?.map((category, index) => (
                        <div key={index} className={styles.category}>
                            {category.icon && (
                                <div
                                    className={`flex ${styles["imageContainer"]}`}
                                >
                                    <div className="relative w-full">
                                        <Image
                                            src={category.icon}
                                            layout="fill"
                                            alt={category.title ?? ""}
                                        />
                                    </div>
                                </div>
                            )}
                            <h3
                                dangerouslySetInnerHTML={{
                                    __html: category.title ?? "",
                                }}
                            />
                            {category.description && (
                                <p className={styles.description}>
                                    {category.description}
                                </p>
                            )}

                            <a
                                href={`${category.link}?${queryString}`}
                                className={
                                    addLinkButton
                                        ? styles["with-button"]
                                        : styles["without-button"]
                                }
                            >
                                {category.buttonText}
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles["no-search-results-text"]}>
                    Sorry, No Results Found
                </div>
            )}
        </div>
    );
}
